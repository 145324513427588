// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-asset-managers-en-jsx": () => import("./../../../src/pages/asset-managers.en.jsx" /* webpackChunkName: "component---src-pages-asset-managers-en-jsx" */),
  "component---src-pages-asset-managers-sv-jsx": () => import("./../../../src/pages/asset-managers.sv.jsx" /* webpackChunkName: "component---src-pages-asset-managers-sv-jsx" */),
  "component---src-pages-book-a-demo-en-jsx": () => import("./../../../src/pages/book-a-demo.en.jsx" /* webpackChunkName: "component---src-pages-book-a-demo-en-jsx" */),
  "component---src-pages-book-a-demo-success-en-jsx": () => import("./../../../src/pages/book-a-demo-success.en.jsx" /* webpackChunkName: "component---src-pages-book-a-demo-success-en-jsx" */),
  "component---src-pages-book-a-demo-success-sv-jsx": () => import("./../../../src/pages/book-a-demo-success.sv.jsx" /* webpackChunkName: "component---src-pages-book-a-demo-success-sv-jsx" */),
  "component---src-pages-book-a-demo-sv-jsx": () => import("./../../../src/pages/book-a-demo.sv.jsx" /* webpackChunkName: "component---src-pages-book-a-demo-sv-jsx" */),
  "component---src-pages-family-offices-en-jsx": () => import("./../../../src/pages/family-offices.en.jsx" /* webpackChunkName: "component---src-pages-family-offices-en-jsx" */),
  "component---src-pages-family-offices-sv-jsx": () => import("./../../../src/pages/family-offices.sv.jsx" /* webpackChunkName: "component---src-pages-family-offices-sv-jsx" */),
  "component---src-pages-financial-advisors-en-jsx": () => import("./../../../src/pages/financial-advisors.en.jsx" /* webpackChunkName: "component---src-pages-financial-advisors-en-jsx" */),
  "component---src-pages-financial-advisors-sv-jsx": () => import("./../../../src/pages/financial-advisors.sv.jsx" /* webpackChunkName: "component---src-pages-financial-advisors-sv-jsx" */),
  "component---src-pages-index-en-jsx": () => import("./../../../src/pages/index.en.jsx" /* webpackChunkName: "component---src-pages-index-en-jsx" */),
  "component---src-pages-index-sv-jsx": () => import("./../../../src/pages/index.sv.jsx" /* webpackChunkName: "component---src-pages-index-sv-jsx" */),
  "component---src-pages-press-releases-en-jsx": () => import("./../../../src/pages/press-releases.en.jsx" /* webpackChunkName: "component---src-pages-press-releases-en-jsx" */),
  "component---src-pages-press-releases-sv-jsx": () => import("./../../../src/pages/press-releases.sv.jsx" /* webpackChunkName: "component---src-pages-press-releases-sv-jsx" */),
  "component---src-pages-sharpfin-insider-en-jsx": () => import("./../../../src/pages/sharpfin-insider.en.jsx" /* webpackChunkName: "component---src-pages-sharpfin-insider-en-jsx" */),
  "component---src-pages-sharpfin-insider-sv-jsx": () => import("./../../../src/pages/sharpfin-insider.sv.jsx" /* webpackChunkName: "component---src-pages-sharpfin-insider-sv-jsx" */),
  "component---src-pages-solutions-en-jsx": () => import("./../../../src/pages/solutions.en.jsx" /* webpackChunkName: "component---src-pages-solutions-en-jsx" */),
  "component---src-pages-solutions-sv-jsx": () => import("./../../../src/pages/solutions.sv.jsx" /* webpackChunkName: "component---src-pages-solutions-sv-jsx" */),
  "component---src-pages-success-en-jsx": () => import("./../../../src/pages/success.en.jsx" /* webpackChunkName: "component---src-pages-success-en-jsx" */),
  "component---src-pages-success-sv-jsx": () => import("./../../../src/pages/success.sv.jsx" /* webpackChunkName: "component---src-pages-success-sv-jsx" */),
  "component---src-pages-team-en-jsx": () => import("./../../../src/pages/team.en.jsx" /* webpackChunkName: "component---src-pages-team-en-jsx" */),
  "component---src-pages-team-sv-jsx": () => import("./../../../src/pages/team.sv.jsx" /* webpackChunkName: "component---src-pages-team-sv-jsx" */),
  "component---src-pages-whitepaper-en-jsx": () => import("./../../../src/pages/whitepaper.en.jsx" /* webpackChunkName: "component---src-pages-whitepaper-en-jsx" */),
  "component---src-pages-whitepaper-success-en-jsx": () => import("./../../../src/pages/whitepaper-success.en.jsx" /* webpackChunkName: "component---src-pages-whitepaper-success-en-jsx" */),
  "component---src-pages-whitepaper-success-sv-jsx": () => import("./../../../src/pages/whitepaper-success.sv.jsx" /* webpackChunkName: "component---src-pages-whitepaper-success-sv-jsx" */),
  "component---src-pages-whitepaper-sv-jsx": () => import("./../../../src/pages/whitepaper.sv.jsx" /* webpackChunkName: "component---src-pages-whitepaper-sv-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-press-release-jsx": () => import("./../../../src/templates/press-release.jsx" /* webpackChunkName: "component---src-templates-press-release-jsx" */)
}

